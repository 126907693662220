// --------------------------------------- Project List
export const ProjectList = [
  {
    img: "/space-guardian.png",
    title: "Space Guardian",
    description:
      "A shoot'em'up based game. My first time using the framework Phaser 3 and vanilla JavaScript for making games.",
    tech_stack: ["JavaScript", "Phaser3"],
    github_url: "",
    demo_url: "https://space-guardian.tienphatdev.io.vn/",
  },
  {
    img: "/qr-generator.png",
    title: "QR Generator",
    description:
      "A QR Generator site with short url for me to use whenever I need it.",
    tech_stack: ["ReactJS", "TailwindCSS"],
    github_url: "https://github.com/Niko1444/qr_generator",
    demo_url: "https://qr-nikola.vercel.app/",
  },
  {
    img: "/imbc.png",
    title: "IMBC2024 Contest Website",
    description:
      "Official website for the contest IMBC2024 of UMP (University of Medicine and Pharmacy HCMC).",
    tech_stack: ["ReactJs", "NodeJs", "Render", "MUI (Material-UI)"],
    github_url: "",
    demo_url: "https://imbc2024.com/",
  },
  {
    img: "/capoo-game.png",
    title: "Capoo Game",
    description:
      "A cat-playing role game with Capoo. We use Java and it's JFC, Java Swing to make the game.",
    tech_stack: ["Java", "JavaSwing"],
    github_url: "https://github.com/ThienHuongz/Capoo",
    demo_url: "",
  },
];

// --------------------------------------- Skills

export const stackList = [
  // {
  //   img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/html5/html5-original.svg",
  //   name: "HTML",
  // },
  // {
  //   img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/css3/css3-original.svg",
  //   name: "CSS",
  // },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/javascript/javascript-original.svg",
    name: "JavaScript",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/react/react-original.svg",
    name: "React",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/nextjs/nextjs-original.svg",
    name: "NextJs",
  },
  // {
  //   img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/typescript/typescript-original.svg",
  //   name: "Typescript",
  // },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/git/git-original.svg",
    name: "Git",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/nodejs/nodejs-original.svg",
    name: "NodeJS",
  },
  // {
  //   img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/firebase/firebase-plain.svg",
  //   name: "Firebase",
  // },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/mongodb/mongodb-original.svg",
    name: "MongoDB",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/python/python-original.svg",
    name: "Python",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/c/c-original.svg",
    name: "C",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/java/java-original.svg",
    name: "Java",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/mysql/mysql-original.svg",
    name: "MySQL",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/bootstrap/bootstrap-original.svg",
    name: "Bootstrap",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/materialui/materialui-original.svg",
    name: "Material UI",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/figma/figma-original.svg",
    name: "Figma",
  },
];
